// @flow
import React from 'react';

import { Box } from '@chakra-ui/react';
import withNavigation from '../main/WithRoutes';
import T2dCalculator from '../t2d/calculator/T2dCalculator';
import { getPatientId } from './capilots-utils';
import T2dExplanatorySection from '../t2d/T2dExplanatorySection';
import T2dChartsSection from '../t2d/T2dChartsSection';
import T2dRecommendationSection from '../t2d/T2dRecommendationSection';
import t2dImage from '../images/sugar-blood-level.png';

import calcBmi from '../utils/bmi';
import t2dReportCommon from '../t2d/t2d-report-common';
import PatientAttributes from "../common/reports/PatientAttributes";
import {roundWithPrecision} from "../utils/numbers";
import {mmolL2mgdl} from "../utils/cholesterol";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import {t2dCalculatorUserAttributes} from "../common/reports/report-common";
import {isNullOrUndefined} from "url/util";
import {isSelfUrl} from "../main/routes";
import {mmolmol2percents} from "../utils/a1c";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import MobileT2dCalculator from "../t2d/calculator/MobileT2dCalculator";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  age: ?number,
  printing: boolean,
  patientId: ?string,
  t2d: Object,
  pca: Array<number>,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean,
  hasGeneticTest: boolean
};

type State = {
  overallRiskPercentile: ?number
};

export class PilotT2dRiskReportImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).onInitOverallRiskPercentile = this.onInitOverallRiskPercentile.bind(this);
    (this: any).getPartialDataAlert = this.getPartialDataAlert.bind(this);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
    this.state = {
      overallRiskPercentile: undefined
    };
  }
  state: State;
  
  onInitOverallRiskPercentile(percentile: number) {
    this.setState({
      overallRiskPercentile: percentile
    });
  }

  getPartialDataAlert() {
    if (isSelfUrl(this.props.location.pathname)) {
      return 'The overall risk assessment is based on partial data.\nFill in your attributes in order to get a more accurate overall risk assessment.'
    }
    return 'NOTE: the calculated overall risk is based on partially available clinical data and therefore may be less accurate.'
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    const quintile: number = this.props.t2d
      ? Math.floor(this.props.t2d.t2d.percentile / 20)
      : 0;
    let bmi: ?number;
    if (this.props.visit) {
      if (this.props.visit.bmi) {
        ({ bmi } = this.props.visit);
      } else {
        bmi = calcBmi(this.props.visit.weight, this.props.visit.height / 100);
      }
    }
    const showAlert = !this.props.loading && this.props.visit && (
        isNullOrUndefined(this.props.visit.sex) ||
        !this.props.visit.age ||
        !this.props.visit.dbp ||
        !this.props.visit.sbp ||
        !this.props.visit.hdl ||
        !this.props.visit.ldl ||
        !this.props.visit.tc
    );
    const alertMessage = showAlert ? this.getPartialDataAlert() : undefined;

    let overallPercentile = 0;
    if (this.state.overallRiskPercentile !== undefined && this.state.overallRiskPercentile !== null) {
      overallPercentile = this.state.overallRiskPercentile + 1;
    }

    const chartsSection = this.props.t2d && this.props.hasGeneticTest ?  <Box>
      <T2dChartsSection
        patientId={this.patientId}
        percentile={this.props.t2d.t2d.percentile}
        quintile={Math.floor(this.props.t2d.t2d.percentile / 20)}
        oddsRatios={this.props.t2d.t2d.odds_ratio_list}
        deciles={this.props.t2d.t2d.deciles}
        isMobile={isMobile}
        overallRiskPercentile={overallPercentile}
        overallRiskDeciles={this.props.t2d.t2d.overall_risk.prevalence}
        overallRiskOddsRatios={this.props.t2d.t2d.overall_risk.odds_ratios}
        printing={this.props.printing}
      />
    </Box> : null;

    const calculator = this.props.t2d && this.props.visit ? (
      <Box
          mt="20px"
          sx={{
            '@media print': {
              pageBreakBefore: 'always;'
            }
          }}
      >
        {isMobile && <MobileT2dCalculator
          sbp={this.props.visit.sbp}
          dbp={this.props.visit.dbp}
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          bmi={bmi}
          hdl={this.props.visit.hdl}
          tc={this.props.visit.tc}
          a1c={this.props.visit.a1c}
          fhDiabetes={this.props.visit.fh_diabetes}
          bpTreated={this.props.visit.bp_treated}
          smoker={this.props.visit.smoker}
          t2dScore={this.props.t2d.t2d.overall_risk_t2d}
          stdizedT2dScore={this.props.t2d.t2d.stdized_overall_risk_t2d}
          t2dQuintile={quintile}
          t2dPercentile={this.props.t2d.t2d.percentile + 1}
          t2dLogReg={this.props.t2d.t2d.log_reg}
          t2dLogRegA1c={this.props.t2d.t2d.log_reg_w_a1c}
          callbackOnChange={null}
          orderLabs={false}
          pcs={this.props.pca}
          printing={this.props.printing}
          callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
          hasGeneticTest={this.props.hasGeneticTest}
        />}
        {!isMobile && <T2dCalculator
          sbp={this.props.visit.sbp}
          dbp={this.props.visit.dbp}
          sex={this.props.visit.sex ? 1 : 0}
          age={this.props.age}
          bmi={bmi}
          hdl={this.props.visit.hdl}
          tc={this.props.visit.tc}
          a1c={this.props.visit.a1c}
          fhDiabetes={this.props.visit.fh_diabetes}
          bpTreated={this.props.visit.bp_treated}
          smoker={this.props.visit.smoker}
          t2dScore={this.props.t2d.t2d.overall_risk_t2d}
          stdizedT2dScore={this.props.t2d.t2d.stdized_overall_risk_t2d}
          t2dQuintile={quintile}
          t2dPercentile={this.props.t2d.t2d.percentile + 1}
          t2dLogReg={this.props.t2d.t2d.log_reg}
          t2dLogRegA1c={this.props.t2d.t2d.log_reg_w_a1c}
          callbackOnChange={null}
          orderLabs={false}
          pcs={this.props.pca}
          printing={this.props.printing}
          callbackInitRiskPercentile={this.onInitOverallRiskPercentile}
          hasGeneticTest={this.props.hasGeneticTest}
        />}
      </Box>
    ) : null;

    const printingPatientAttr = this.props.printing && this.props.t2d &&
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            bmi={roundWithPrecision(this.props.visit.bmi)}
            sbp={this.props.visit.sbp}
            dbp={this.props.visit.dbp}
            hdl={roundWithPrecision(mmolL2mgdl(this.props.visit.hdl), 0)}
            ldl={roundWithPrecision(mmolL2mgdl(this.props.visit.ldl), 0)}
            tc={roundWithPrecision(mmolL2mgdl(this.props.visit.tc), 0)}
            a1c={roundWithPrecision(mmolmol2percents(this.props.visit.a1c), 1)}
            riskTrait="T2D"
            riskLevel={this.props.hasGeneticTest ? getRiskLevelByPercentile(this.props.t2d.t2d.percentile + 1) : "NA"}
            bpTreated={this.props.visit.bp_treated}
            diabetes={this.props.visit.diabetes}
            smoker={this.props.visit.smoker}
            fhDiabetes={this.props.visit.fh_diabetes}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={t2dCalculatorUserAttributes}
            spacing={"0px"}
          >
          </PatientAttributes>
        </Box>;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="TYPE 2 DIABETES RISK REPORT"
          configTrait="t2d"
          loading={this.props.loading}
          titleImage={t2dImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          alertMessage={alertMessage}
          showAlert={showAlert}
          riskPercentile={this.props.t2d ? this.props.t2d.t2d.percentile : null}
          geneticRiskInfoText={t2dReportCommon.GENETIC_RISK_INFO_TEXT}
          patientAttributesSection={printingPatientAttr}
          chartsSection={chartsSection}
          calculatorSection={calculator}
          recommendationSection={!this.props.isClinicianView && <T2dRecommendationSection isMobile={isMobile} />}
          explanatorySection={<T2dExplanatorySection isMobile={isMobile} />}
          hasGeneticTest={this.props.hasGeneticTest}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotT2dRiskReportImpl));
