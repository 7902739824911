// @flow
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API_URLS, SERVICES } from '../config/url_config';
import requests from '../utils/request';
import {extractDepartment, splitPatientAndPractice} from "../athena/athena";
import {isNullOrUndefined} from "../utils/numbers";
import {getCurrentUsername} from "../auth/auth";

export function fetchPatientCardioRisks(patientId: string): Promise<*> {
  return fetchPatientRisks(patientId, "cardio")
}


export function fetchPatientCancerRisks(patientId: string): Promise<*> {
  return fetchPatientRisks(patientId, "cancer")
}


export function fetchPatientMonogenicCancerRisks(patientId: string): Promise<*> {
  return fetchPatientRisks(patientId, "cancer/monogenic")
}


export function fetchPatientRisks(patientId: string, riskType: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.RISK}/${riskType}/${patientId}`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {});
}

export function loadPatients(
  clinicName: string,
  doctorName: string,
  sortBy: ?string
): Promise<*> {
  const additionalParams: Object = {
    queryParams: {
      clinic: clinicName
    }
  };
  if (doctorName) {
    additionalParams.queryParams.doctor = doctorName;
  }
  if (sortBy) {
    additionalParams.queryParams.sort = sortBy;
  }
  return requests.get(
    SERVICES.CAPILOTS,
    {},
    API_URLS.CAPILOTS.GET_PATIENTS,
    additionalParams
  );
}

export function addPatient(
  patientName: string,
  kitId: string,
  sexPatient: boolean,
  birthYear: number,
  birthMonth: number,
  heightPatient: number,
  clinicPatient: string,
  doctorPatient: string,
  statusPatient: string,
  externalId: string,
  email: string,
  phone: string
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.ADD_PATIENT}`;

  const body = {
    patient_name: patientName,
    kit_id: kitId,
    sex: sexPatient,
    birth_year: birthYear,
    birth_month: birthMonth,
    height: heightPatient,
    clinic: clinicPatient,
    doctor: doctorPatient,
    status: statusPatient,
    external_id: externalId,
    email: email,
    phone: phone
  };

  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function updatePatient(
  patientId: string,
  patientName: string,
  kitId: string,
  sexPatient: boolean,
  birthYear: number,
  birthMonth: number,
  heightPatient: number,
  externalId: string,
  email: string,
  phone: string
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_PATIENT}/${patientId}`;

  const body = {
    patient_name: patientName,
    kit_id: kitId,
    sex: sexPatient,
    birth_year: birthYear,
    birth_month: birthMonth,
    height: heightPatient,
    external_id: externalId,
    email: email,
    phone: phone
  };
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function updatePatientAttributes(
  patientId: string,
  sexPatient: boolean,
  birthYear: ?number,
  birthMonth: ?number,
  heightPatient: ?number,
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_PATIENT}/${patientId}`;

  const body = {
    sex: sexPatient,
    birth_year: birthYear,
    birth_month: birthMonth,
    height: heightPatient
  };
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

type EmailOptionalPhone = {
  email: string,
  phone?: string
}

export function updatePatientEmailAndPhone(
  patientId: string,
  email: string,
  phone: ?string
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_PATIENT}/${patientId}`;

  const body: EmailOptionalPhone = {
    email: email,
  };
  if (phone) {
    body["phone"] = phone
  }
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function updatePatientConsent(
  patientId: string,
  consent: boolean
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_PATIENT}/${patientId}`;

  const body = {
    consent: consent
  };
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function updatePatientOrders(
  patientId: string,
  orderedTests: number
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_PATIENT}/${patientId}`;

  const body = {
    ordered_tests: orderedTests
  };
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function addOrder(
  patientId: string,
  orderType: string,
  orderedBy: ?string
): Promise<*> {
  const body = {
    patient_id: patientId,
    order_type: orderType,
    source: "OpenDNA",
    ordered_by: isNullOrUndefined(orderedBy) ? getCurrentUsername() : orderedBy
  };
  const path = `${API_URLS.CAPILOTS.ADD_ORDER}`;
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function addVisit(
  patientId: string,
  weight: ?number,
  sbp: ?number,
  dbp: ?number,
  hdl: ?number,
  ldl: ?number,
  tc: ?number,
  bmi: ?number,
  bpTreated: ?boolean,
  diabetes: boolean,
  fhDiabetes: boolean,
  a1c: ?number,
  smoker: ?boolean,
  hypertension: ?boolean = null,
  hypercholesterol: ?boolean = null,
  exercise: ?boolean = null
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.ADD_VISIT}`;

  const body = {
    patient_id: patientId,
    weight,
    sbp,
    dbp,
    hdl,
    ldl,
    tc,
    bmi,
    bp_treated: bpTreated,
    diabetes,
    fh_diabetes: fhDiabetes,
    a1c,
    smoker,
    hypertension,
    hypercholesterol,
    exercise
  };

  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function addVisitDynamicAttrs(
  patientId: string,
  attrs: Object
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.ADD_VISIT}`;

  const body = {...attrs};
  body["patient_id"] = patientId;
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function unionPatientVisit(patientId: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UNION_VISIT}/${patientId}`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {});
}

export function getPatient(patientId: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.GET_PATIENT}/${patientId}`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {});
}

export function getPatientByUsername(cognitoUser: typeof CognitoUser): Promise<*> {
  const username = cognitoUser.getUsername();
  console.log("getPatientByUsername");
  console.log(username);
  const path = `${API_URLS.CAPILOTS.GET_PATIENT_BY_USERNAME}/${username}`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {}, cognitoUser);
}

export async function getPatientIdByUsername(cognitoUser: typeof CognitoUser) {
  if (!cognitoUser) return null;
  const patient = await getPatientByUsername(cognitoUser);
  return patient.data.patient_id;
}

export async function postAthenaPatient(athenaPatient: string|number, practice: string|number, department: string|number) {
  const body = {
      patient: athenaPatient,
      practice: practice,
      department: department
    };
  const path = `${API_URLS.CAPILOTS.ATHENA_PATIENT}`;
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export async function getOpendnaPatientFromAthenaPatient(token: Object) {
  const patientAndPractice = splitPatientAndPractice(token.patient);
  const department = extractDepartment(token.ah_department);
  const response = await postAthenaPatient(patientAndPractice["patient"], patientAndPractice["practice"], department);
  return response.data;
}


export function createPresignedUrlForUpload(): Promise<*> {
  const path = `${API_URLS.CAPILOTS.URL}`;
  const body = { folder: "barcode" }
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function loadPreScannedKitId(entry: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.KITID}/${entry}`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {});
}

export function assignUserToPatient(
  patientId: string,
  username: ?string,
  consumer: boolean,
  phone: ?string
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.ASSIGN_USER}/${patientId}`;

  const body = { "username": username, "consumer": consumer, "phone": phone};
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function getKitAvailability(kit: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.KITID}/${kit}/availability`;
  return requests.get(SERVICES.CAPILOTS, {}, path, {});
}


export function updatePotentialPatient(
  recordId: string,
  isInterested: boolean
): Promise<*> {
  const path = `${API_URLS.CAPILOTS.UPDATE_POTENTIAL}/${recordId}`;

  const body = {
    interested: isInterested
  };
  return requests.put(SERVICES.CAPILOTS, {}, path, {}, body);
}

export function increasePotentialPatientAccessCount(recordId: string): Promise<*> {
  const path = `${API_URLS.CAPILOTS.INCREASE_POTENTIAL_ACCESS_COUNT}/${recordId}/access`;
  const body = {
    increase: true
  };
  return requests.post(SERVICES.CAPILOTS, {}, path, {}, body);
}
