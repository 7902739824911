// @flow
import COLORS from './colors';

export const RISK_COLORS = {
  BLUE: 'rgba(1, 87, 165, 1)',
  LIGHT_GREEN: 'rgba(166, 190, 15, 1)',
  YELLOW: 'rgba(247, 214, 37, 1)',
  LIGHT_ORANGE: 'rgba(247, 170, 50, 1)',
  ORANGE: 'rgba(247, 101, 27, 1)',
  RED: 'rgba(237, 28, 27, 1)',
  DARK_RED: 'rgba(138, 1, 1, 1)'
};

export function getRiskColorByPercentile(
  percentile: number,
  reverse: boolean = false
) {
  if (reverse) {
    if (percentile <= 5) return RISK_COLORS.RED;
    if (percentile <= 15) return RISK_COLORS.ORANGE;
    if (percentile <= 30) return RISK_COLORS.LIGHT_ORANGE;
    if (percentile <= 50) return RISK_COLORS.YELLOW;
    return RISK_COLORS.LIGHT_GREEN;
  }
  if (percentile <= 50) return RISK_COLORS.LIGHT_GREEN;
  if (percentile <= 70) return RISK_COLORS.YELLOW;
  if (percentile <= 85) return RISK_COLORS.LIGHT_ORANGE;
  if (percentile <= 95) return RISK_COLORS.ORANGE;
  return RISK_COLORS.RED;
}

export function getRiskColorLight(quantile: number, numQuantiles: number = 4) {
  if (quantile === 0) return COLORS.GREEN_STATUS_SLIGHT_TRANSPARENT;
  if (quantile === numQuantiles - 1)
    return COLORS.RED_STATUS_SLIGHT_TRANSPARENT;
  return COLORS.YELLOW_STATUS_SLIGHT_TRANSPARENT;
}

export function getRiskColorByQuartile(quartile: number) {
  if (quartile === 0) return COLORS.GREEN_STATUS;
  if (quartile <= 2) return COLORS.YELLOW_STATUS;
  return COLORS.RED_STATUS;
}

export function getRiskLevelByQuartile(quartile: number) {
  if (quartile === 0) return 'Low';
  if (quartile <= 2) return 'Moderate';
  return 'High';
}

export function getRiskColorByQuintile(quintile: number) {
  if (quintile === 0) return COLORS.GREEN_STATUS;
  if (quintile <= 3) return COLORS.YELLOW_STATUS;
  return COLORS.RED_STATUS;
}

export function getRiskLevelByPercentile(percentile: ?number) {
  if (!percentile) return '';
  if (percentile <= 50) return 'Normal';
  if (percentile <= 70) return 'Above average';
  if (percentile <= 85) return 'High';
  if (percentile <= 95) return 'Very high';
  return 'Extremely high';
}

export function getRiskLevelByQuintile(quintile: number) {
  if (quintile === 0) return 'Low';
  if (quintile <= 3) return 'Moderate';
  return 'High';
}

export function getRiskColorByLevel(level: string) {
  const l = level.toLowerCase();
  if (l === 'low') return COLORS.GREEN_STATUS;
  if (l === 'high') return COLORS.RED_STATUS;
  return COLORS.YELLOW_STATUS;
}

export function getCadRiskColor(risk: number) {
  if (risk < 5) return RISK_COLORS.LIGHT_GREEN;
  if (risk < 7.5) return RISK_COLORS.YELLOW;
  if (risk < 20) return RISK_COLORS.ORANGE;
  return RISK_COLORS.RED;
}

export function getCadRiskLevel(risk: ?number) {
  if (risk === undefined || risk === null ) return '';
  if (risk < 5) return 'Low risk';
  if (risk < 7.5) return 'Borderline risk';
  if (risk < 20) return 'Intermediate risk';
  return 'High risk';
}

export function getBmiRiskColor(risk: number) {
  if (risk === undefined || risk === null ) return '';
  if (risk < 18.5) return RISK_COLORS.ORANGE;
  if (risk < 25) return RISK_COLORS.LIGHT_GREEN;
  if (risk < 30) return RISK_COLORS.YELLOW;
  if (risk < 40) return RISK_COLORS.RED;
  return RISK_COLORS.DARK_RED;
}

export function getBmiRiskColorByGaugeValue(risk: number) {
  return getBmiRiskColor(risk + 15);
}

export function getBmiRiskLevel(risk: ?number) {
  if (risk === undefined || risk === null ) return '';
  if (risk < 18.5) return 'Underweight';
  if (risk < 25) return 'Normal';
  if (risk < 30) return 'Overweight';
  if (risk < 40) return 'Obesity';
  return 'Severe Obesity';
}

export function getBmiRiskLevelByGaugeValue(risk: number) {
  return getBmiRiskLevel(risk + 15);
}
