// @flow
import React, {useCallback, useEffect} from 'react';
import {Flex, Box} from '@chakra-ui/react';

import withNavigation from '../main/WithRoutes';
import LeftBar from "./LeftBar";
import PilotCardioReport from "./PilotCardioReport";
import PilotCancerReport from "./PilotCancerReport";
import withPilotPatientVisit from "./withPilotPatientVisit";
import {TestType} from "../utils/test_types";
import queryString from "query-string";
import {MainMenuContext} from "../context/TopBarContext";
import MobileReportsMenu from "./MobileReportsMenu";
import withMobile from "../common/mobile/withMobile";
import InProgressImpl from "../common/InProgress";
import {v4 as uuid4} from "uuid";

type Props = {
  params: any,
  navigate: any,
  location: any,
	visit: any,
	patientId: ?string,
	isMobile: boolean
};


export function PilotPatientRisksImpl(props: Props) {
	const [, setMainMenuContent] = React.useContext(MainMenuContext);
  const queryParams = queryString.parse(props.location.search);
	const getInitialGroup = useCallback(()=> {
		if (queryParams.group === "cancer") {
			return "cancer"
		}
		return "cardio"
	}, [queryParams])
  const [selectedGroup, setSelectedGroup] = React.useState(getInitialGroup());
  const [selectedTab, setSelectedTab] = React.useState(undefined);
  const [obesityDataAvailable, setObesityDataAvailable] = React.useState(false);
  const [leftBarKey, setLeftBarKey] = React.useState(1);
	const onSelectedItemChange = useCallback((group, item) => {
		setSelectedGroup(group);
		setSelectedTab(item);
	}, [setSelectedGroup, setSelectedTab])
	const showCardio = selectedGroup === "cardio";
	const showCancer = selectedGroup === "cancer";
	const completed_tests = props.visit ? props.visit.completed_tests : TestType.CARDIOMETABOLIC_RISK;
	const sex = props.visit ? props.visit.sex : undefined;
  const {isMobile} = props;

	const onCallbackSwitchTab = useCallback((newTab) => {
		onSelectedItemChange(selectedGroup, newTab);
	}, [onSelectedItemChange, selectedGroup])

	const callbackObesityDataAvailable = useCallback((available) => {
		setObesityDataAvailable(available);
		setLeftBarKey(uuid4());
	}, [setObesityDataAvailable])

	const callbackIsDisabledItem = useCallback((item) => {
		if (item.name !== "Obesity") return false;
			return !obesityDataAvailable;
	}, [obesityDataAvailable])

	useEffect(() => {
		if (isMobile) {
			setMainMenuContent(<MobileReportsMenu onSelectedItemChange={onSelectedItemChange} selectedGroup={selectedGroup} completed_tests={completed_tests} sex={sex} externallySelectedItem={selectedTab}/>);
		}
	}, [isMobile, onSelectedItemChange, setMainMenuContent, selectedGroup, completed_tests, selectedTab, sex]);

	return (
		<>
			{!isMobile && (
				<Flex w="100%">
					{/*left bar*/}
					<Box w={"10%"} minW={"200px"}>
						{props.visit && <LeftBar onSelectedItemChange={onSelectedItemChange} selectedGroup={selectedGroup} completed_tests={completed_tests} sex={sex} externallySelectedItem={selectedTab} callbackIsDisabledItem={callbackIsDisabledItem} key={leftBarKey}/>}
						{!props.visit && <InProgressImpl label={"loading..."} mt={"200px"}/>}
					</Box>
					{/*report pane*/}
					<Box minW={"90%"}>
						{showCardio && <PilotCardioReport patientId={props.patientId} selectedTabName={selectedTab} callbackObesityDataAvailable={callbackObesityDataAvailable}/>}
						{showCancer && <PilotCancerReport patientId={props.patientId} selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
					</Box>
				</Flex>)}
			{isMobile && (
				<Box w="100%">
					<Box>
						{showCardio && <PilotCardioReport patientId={props.patientId} selectedTabName={selectedTab} callbackObesityDataAvailable={callbackObesityDataAvailable}/>}
						{showCancer && <PilotCancerReport patientId={props.patientId} selectedTabName={selectedTab} callbackOnSwitchTab={onCallbackSwitchTab}/>}
					</Box>
				</Box>)}
		</>
	);
}

export default withNavigation(withPilotPatientVisit(withMobile(PilotPatientRisksImpl)));
