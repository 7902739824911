import React from "react";
import ActionButton from "./ActionButton";
import COLORS from "./colors";
import {
  Box,
  Button,
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay, Spacer,
  useDisclosure
} from "@chakra-ui/react";
import { IoVideocamOutline } from "react-icons/io5"

type Props = {
  buttonName: string,
	title: string,
  fileName: string,
  duration: string
};

export default function HowToVideo(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const fullButtonName = `${props.buttonName}...`;

  return (
    <Box>
      <ActionButton
        fontSize={13}
        onClick={onOpen}
        name={null}
        borderWidth={0}
        color={"gray.600"}
        bg={"transparent"}
      >
        <Flex align={"top"}>
          <Text mt="2px" textDecoration="underline">{fullButtonName}</Text>
          <Box ml={"3px"}>
            <Box as={IoVideocamOutline} h="18px" w="18px" ml="10px" />
            <Text mt="-4px" fontSize={10} >{`(${props.duration})`}</Text>
          </Box>
        </Flex>
      </ActionButton>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        isCentered
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT} maxH={"80%"} bg={COLORS.DARK_BLUE}>
          <ModalBody >
            <Flex color={COLORS.REPORT_TEXT} ml={"30px"}>
              <Spacer/>
              <Box >
                <Flex my="20px" align={"center"} width={"80%"}>
                  <Text fontWeight={"bold"} fontSize={18} color={"white"}>{props.title} </Text>
                  <Spacer/>
                  <Button color={COLORS.REPORT_TEXT} onClick={onClose}>Close</Button>
                </Flex>
                <video controls height="80%" width={"80%"} style={{
                  borderRadius: "10px",
                }}>
                  <source src={`/videos/${props.fileName}.mp4`} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Spacer/>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
  </Box>)
};
