// @flow
import React, {useCallback} from 'react';
import {Box, Flex, Text, Spacer, Image} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import IconTextAndLink from '../common/IconTextAndLink';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import firstAidImage from '../images/first-aid-kit.png';
import COLORS from "../common/colors";
import alcoholImage from "../images/alcohol.png";
import {getTargetWeight} from "../utils/bmi";
import {kgToPounds} from "../utils/unit_translation";

type Props = {
  isMobile: boolean,
  height: number,
  weight: number,
  bmi: number
};

export default function ObesityPersonalizedRecommendations(props: Props) {
  const {height, bmi} = props;
  const overweight = bmi > 25;
  const getDisplayTargetWeight = useCallback(()=> {
      const targetWeight = kgToPounds(getTargetWeight(25, height/100));
      if (!targetWeight) return "";
      return `${Math.floor(targetWeight)} pounds`
    }, [height])

  return (
    <Box ml={"20px"} mt="10px" color={COLORS.REPORT_TEXT}>
      <Box color={"gray.600"} bg={"gray.50"} borderColor={"gray.400"} borderWidth={1}>
        <Box m="20px">
          <Flex w={"100%"} mt={"10px"} align={"center"}>
            <Spacer/>
            <Text as={"u"} fontSize={14} fontWeight={"bold"}>Treatment Suggestions</Text>
            <Spacer/>
            <Image src={firstAidImage} h={"32px"}/>
          </Flex>
          {overweight && <Box>
            <IconTextAndLink
              imageSrc={dietImage}
              text="Preserve a healthy diet to reduce weight"
              linkText="more info..."
              link="https://www.heart.org/en/healthy-living/healthy-eating/losing-weight"
              isMobile={props.isMobile}
              size={'small'}
              mt={"10px"}
              linkStyle={'link'}
              textWidth={"65%"}
            />
            {bmi &&
              <Text ml={"30px"} fontSize={12} color={COLORS.GREEN_STATUS} fontWeight={"bold"}>{`Target weight to get to normal BMI levels is ${getDisplayTargetWeight()}`}</Text>
            }
					  <IconTextAndLink
							imageSrc={alcoholImage}
							text="Limited alcohol"
							linkText="more info..."
							link="https://www.betterhealth.vic.gov.au/health/healthyliving/Alcohol-and-weight-gain"
							isMobile={props.isMobile}
							size={'small'}
							mt={"10px"}
							linkStyle={'link'}
							textWidth={"65%"}
						/>
          </Box>}
          <IconTextAndLink
            imageSrc={runningImage}
            text="Exercise"
            linkText="more info..."
            link="https://www.heart.org/en/healthy-living/fitness/fitness-basics/aha-recs-for-physical-activity-in-adults"
            isMobile={props.isMobile}
            size={'small'}
            mt={"10px"}
            linkStyle={'link'}
            textWidth={"65%"}
          />
        </Box>
      </Box>
    </Box>
  );
}

ObesityPersonalizedRecommendations.propTypes = {
  isMobile: PropTypes.bool
};

ObesityPersonalizedRecommendations.defaultProps = {
  isMobile: false
};
