// @flow
import React from 'react';
import {Alert, AlertIcon, Box, Flex, Text} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import withNavigation from '../../main/WithRoutes';
import COLORS from '../../common/colors';
import IconAttribute from '../../common/IconAttributes';
import RelativeRiskPersonChart from '../../common/RelativeRiskPersonChart';
import { getPatientId } from '../../demo/filters';
import MobileReportHeaderBar from './MobileReportHeaderBar';
import MobileYourResultsPanel from './MobileYourResultsPanel';
import WhitePanel from './WhitePanel';
import { isShowItem } from './report-config';
import { getRiskLevelByPercentile } from '../risk-colors';
import {isSelfUrl} from "../../main/routes";
import InProgressImpl from "../InProgress";
import withMobile from "../mobile/withMobile";

type Props = {
  location: any,
  params: any,
  reportTitle: string,
  titleImage: any,
  riskTitle: string,
  riskPercentile: number,
  chartsSection: any,
  absRiskPanel: any,
  topMiddlePane: any,
  calculatorSection: any,
  recommendationSection: any,
  explanatorySection: any,
  configTrait: string,
  isDemo: ?boolean,
  loading?: boolean,
  screenWidth: number,
  topRightPane: any,
  hasGeneticTest: boolean
};

type State = {};

export class MobileGeneticReportImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setDisplayConfig();
    this.patientId = getPatientId(props.params, props.location);
    this.isSelfUrl = isSelfUrl(props.location.pathname);
    // this.isIdentifiable = isClinicsUrl(props.location.pathname) || isSelfUrl(props.location.pathname) || isAthenaHealthUrl(props.location.pathname);
  }

  setDisplayConfig() {
    this.showRightTopPanel = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showRightTopPanel'
    );
    this.showChartsSection = isShowItem(
      this.props.location,
      this.props.configTrait,
      'showChartsSection'
    );
  }

  patientId: number;
  showRightTopPanel: boolean;
  showChartsSection: boolean;
  isSelfUrl: boolean;

  render() {
    const riskPercentiles = Array.isArray(this.props.riskPercentile)
      ? this.props.riskPercentile
      : [this.props.riskPercentile];
    // const absRiskPanels = Array.isArray(this.props.absRiskPanel)
    //   ? this.props.absRiskPanel
    //   : [this.props.absRiskPanel];
    const chartSections = Array.isArray(this.props.chartsSection)
      ? this.props.chartsSection
      : [this.props.chartsSection];
    const personChartTitles = Array.isArray(this.props.riskTitle)
      ? this.props.riskTitle
      : [this.props.riskTitle];

    let topMiddlePanels = null;
    if (this.props.topMiddlePane) {
      if (Array.isArray(this.props.topMiddlePane)) {
        topMiddlePanels = this.props.topMiddlePane;
      } else {
        topMiddlePanels = [this.props.topMiddlePane]
      }
    }

    let topRightPanels = undefined;
    if (this.props.topRightPane) {
      topRightPanels = Array.isArray(this.props.topRightPane)
          ? this.props.topRightPane
          : [this.props.topRightPane];
    }

    return (
      <Box bg={COLORS.MOBILE_BACKGROUND} maxW={this.props.screenWidth}>
        <Box>
          <MobileReportHeaderBar
            reportTitle={this.props.reportTitle}
            image={this.props.titleImage}
            isDemo={this.props.isDemo}
            hideRiskReportText={false}
          />
        </Box>
        {this.props.loading && <InProgressImpl label="loading..." />}
        {!this.props.loading && <Box >
          <MobileYourResultsPanel />
          {riskPercentiles.map((riskPercentile, i) => (
            <WhitePanel key={uuid4()} topm={"0px"} bottomm={"0px"}>
              <Text textAlign="center">{personChartTitles[i]}</Text>
              {this.props.hasGeneticTest && <RelativeRiskPersonChart
                percentile={riskPercentile + 1}
                iconHeight={50}
                iconWidth={20}
                title="GENETIC RISK"
                showText={false}
                borderRadius={"60px"}
              />}
              {this.props.hasGeneticTest && <Text mt="5px" textAlign="center" fontSize={13}>
                Your genetic risk is{' '}
                <b>{getRiskLevelByPercentile(riskPercentile)}</b>
              </Text>}
              {!this.props.hasGeneticTest && i === 0 && <Box
                  borderRadius={"20px"}
                  borderColor={'gray.100'}
                  borderWidth={5}
                  bg={COLORS.WARNING_ALERT_BG}
              >
                <Box mx={"20px"} mt={"5px"} pb={"15px"} color={COLORS.REPORT_TEXT}>
                  <Alert status="warning" style={{whiteSpace: 'pre-wrap'}} >
                    <Flex>
                      <AlertIcon mr={"20px"}/>
                      <Text fontWeight={"bold"}>NO GENETICS </Text>
                    </Flex>
                  </Alert>
                  <Text ml="15px" my={"5px"}>The results are based on clinical data only and are therefore less accurate. </Text>
                  <Text ml="15px" mb={"5px"}>Order an <b>OpenDNA genetic test</b> to get a wider and more accurate risk assessment.</Text>
                </Box>
              </Box>
              }
              {topMiddlePanels && topMiddlePanels[i] && (
                <Box mx={"0%"}>
                  {topMiddlePanels[i]}
                </Box>)
              }
              {/* top right pane */}
              {topRightPanels && topRightPanels[i] && (
                <Box mx={"0%"} my={0}>
                  {topRightPanels[i]}
                </Box>
              )}
            </WhitePanel>
          ))}
          {/*in mobile reports the calculator appears before the charts section*/}
          {this.props.calculatorSection && (
            <WhitePanel topm="0px">{this.props.calculatorSection}</WhitePanel>
          )}
          {this.showChartsSection && this.props.chartsSection && (
            <WhitePanel mt="20px">{chartSections}</WhitePanel>
          )}
          {this.props.recommendationSection && <WhitePanel mt="20px">{this.props.recommendationSection}</WhitePanel>}
          <WhitePanel mt="20px">{this.props.explanatorySection}</WhitePanel>
          <Box mx="5%">
            <IconAttribute precedingLine />
          </Box>
        </Box>}
      </Box>
    );
  }
}

export default withNavigation(withMobile(MobileGeneticReportImpl));
