// @flow

export const bpReportStrings: Object = {
  WHAT_IS_HYPERTENSION_TEXT1:
    'High blood pressure is a common condition in which the long-term ' +
    'force of the blood against your artery walls is high enough that ' +
    'it may eventually cause health problems, such as heart disease. ' +
    'Blood pressure is determined both by the amount of blood your ' +
    'heart pumps and the amount of resistance to blood flow in your ' +
    'arteries. The more blood your heart pumps and the narrower your ' +
    'arteries, the higher your blood pressure.',
  WHAT_IS_HYPERTENSION_TEXT2:
    'You can have high blood pressure (hypertension) for years ' +
    'without any symptoms. Even without symptoms, damage to blood ' +
    'vessels and your heart continues and can be detected. ' +
    'Uncontrolled high blood pressure increases your risk of serious ' +
    'health problems. High blood pressure is a leading risk factor ' +
    'for stroke, coronary artery disease and kidney disease ' +
    'responsible for an estimated 7.8 million deaths world-wide',
  WHAT_IS_HYPERTENSION_REF:
    'Forouzanfar MH et al. Global burden of ' +
    'hypertension and systolic blood pressure of at least 100 to ' +
    '115mm Hg, 1990-2015. JAMA; 317.165-182 (2017)',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 7,161,895 variants that are known to be associated ' +
    'with hypertension and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to ' +
    'your risk for increased systolic and diastolic blood pressure ',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared to a ' +
    'group of about 338,000 individuals to calculate ' +
    'your relative risk of increased blood pressure compared to that ' +
    'population.',
  HOW_DID_WE_CALC_REF:
    'Evangelou E et al. Genetic analysis of over 1 million people ' +
    'identifies 535 new loci associated with blood pressure traits. ' +
    'Nat Genet; 50(10):1412-1425',
  WHAT_DOES_IT_MEAN:
    'You can have high blood pressure (hypertension) for years ' +
    'without any symptoms. Even without symptoms, damage to blood ' +
    'vessels and your heart continues and can be detected. ' +
    'Uncontrolled high blood pressure increases your risk of serious ' +
    'health problems, including heart attack and stroke.',
  RISK_FACTORS_EFFECT_REFRAIN:
    'Risk factor effect calculations are based on a linear model ' +
    'derived from analyzing data of ~100K people of European ' +
    'descent and includes genetic risk (PRS), age and sex. The ' +
    'estimated blood pressure changes reflect the mean changes in ' +
    'the population. Blood pressure levels depends on other ' +
    'factors such as physical activity, smoking, stress and other ' +
    'factors and therefore the values presented serve as ' +
    'estimations only.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO HYPERTENSION.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP HYPERTENSION ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP HYPERTENSION.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  CALCULATION_EXPLAINED:
    'Calculations are based on a linear model derived from analyzing ' +
    'data of ~100K people of European descent and includes genetic risk ' +
    '(PRS), age and sex. The estimated blood pressure changes reflect ' +
    'the mean changes in the population. Blood pressure levels depends ' +
    'on other factors such as physical activity, smoking, stress and ' +
    'other factors and therefore the values presented serve as ' +
    'estimations only.',
  GENETIC_RISK_SBP_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 264K variants known to be associated with SBP. ' +
    'The PRS is then compared to a reference scale, built from scores of ~338K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  GENETIC_RISK_DBP_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 338K variants known to be associated with DBP. ' +
    'The PRS is then compared to a reference scale, built from scores of ~338K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  RELATIVE_TO_CURRENT_SBP_INFO_HEADER: 'Risk factor effect on SBP level',
  RELATIVE_TO_CURRENT_DBP_INFO_HEADER: 'Risk factor effect on DBP level',
  RELATIVE_TO_CURRENT_SBP_INFO_TEXT:
    'Current risk factor values of the patient are used to calculate the baseline. ' +
    'Upon risk factor changes, the estimated SBP level is calculated and compared to the baseline, to show the ' +
    'relative increase or decrease as a result of the changes.',
  RELATIVE_TO_CURRENT_DBP_INFO_TEXT:
    'Current risk factor values of the patient are used to calculate the baseline. ' +
    'Upon risk factor changes, the estimated DBP level is calculated and compared to the baseline, to show the ' +
    'relative increase or decrease as a result of the changes.'
};

export function getInsightsFooter(results: Object) {
  if (!('insights' in results)) return '';
  const coveragePercent: string = (
    results.insights.snps_coverage_percent * 100
  ).toFixed(1);
  if (results.insights.snps_coverage_percent < 1) {
    return `The calculation is ${coveragePercent}% accurate compared to a calculation using a complete number of test variants.`;
  }
  return '';
}

export function getInsights(results: Object, attributes: Object) {
  if (attributes === undefined || !('insights' in results)) return '';
  if (attributes.sex !== 0 && attributes.sex !== 1) return '';
  const increase = results.insights.age_effects[5];
  const genderPlural = attributes.sex === 1 ? 'Men' : 'Women';
  let text = `${genderPlural} of the same age and BMI are expected to have a mean increase of ${increase.toFixed(
    1
  )}mmHg after 5 years`;
  const reduction = results.insights.bmi_reductions[5];
  if (reduction && 'bmi' in attributes && attributes.bmi > 25) {
    text = text.concat(
      `, and expected to have a mean decrease of ${reduction.toFixed(
        1
      )}mmHg if they lower their BMI by 5 units.`
    );
  } else {
    text = text.concat('.');
  }
  return text;
}
