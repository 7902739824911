// @flow

const ProstateReportStrings = {
  WHAT_IS_PROSTATE_TEXT1:
    'Prostate cancer is cancer that occurs in the prostate. The prostate is a small walnut-shaped gland ' +
    'in males that produces the seminal fluid that nourishes and transports sperm. ',
  WHAT_IS_PROSTATE_TEXT2:
    'Prostate cancer is one of the most common types of cancer. Many prostate cancers grow slowly and are ' +
    'confined to the prostate gland, where they may not cause serious harm. However, while some types of prostate ' +
    'cancer grow slowly and may need minimal or even no treatment, other types are aggressive and can spread quickly. ' +
    'Accorging to the American Cancer Society, about 1 man in 8 will be diagnosed with prostate cancer during his lifetime.',
  WHAT_IS_PROSTATE_REF1:
    'Rawla P. Epidemiology of Prostate Cancer. World J Oncol. 2019 Apr;10(2):63-89. ' +
    'doi: 10.14740/wjon1191. Epub 2019 Apr 20. PMID: 31068988; PMCID: PMC6497009.',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. OpenDNA ' +
    'scans 232 significant genetic variants and calculates a ' +
    'Polygenic Risk Score (PRS) based on the contribution of each ' +
    'genetic variant to your genetic risk for developing prostate cancer.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 individuals to ' +
    'calculate your relative risk of developing prostate cancer ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Conti DV, Darst BF, Moss LC, et al. Trans-ancestry genome-wide association meta-analysis of prostate cancer ' +
    'identifies new susceptibility loci and informs genetic risk prediction. Nat Genet. 2021 Jan;53(1):65-75. ' +
    'doi: 10.1038/s41588-020-00748-0. Epub 2021 Jan 4. Erratum in: Nat Genet. 2021 Jan 20;: PMID: 33398198; PMCID: PMC8148035.',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO PROSTATE CANCER.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_PROSTATE_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    '232 variants known to be associated with prostate cancer. ' +
    'The PRS is then compared to a reference scale, built from scores of ~157K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  PROSTATE_RISK_IN_POPULATION:
    'The graph shows the risk for having prostate cancer vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 157K individuals. The scores were divided into deciles and the risk of having prostate cancer was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  PROSTATE_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having prostate cancer vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default ProstateReportStrings;
