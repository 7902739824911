import {Alert, AlertIcon, AlertTitle, Box, Divider, Flex, Spacer, Text} from "@chakra-ui/react";
import COLORS from "../common/colors";
import React, {useCallback} from "react";
import cadReportStrings from "./cad-report-common";
import {v4 as uuid4} from "uuid";
import PopoverInfo from "../common/PopoverInfo";

type Props = {
	age: ?number,
	riskColor: string,
	riskLevel: string,
	risk10yrs: ?number,
	lifetimeRisk: ?number,
	lifetimeRisk95: ?number,
	lifetimeRiskColor: string,
	lifetimeRiskLevel: string,
	printing: boolean,
	isMobile: boolean,
	cac: ?number
};

export default function CadRiskSummaryPanel(props: Props) {
	const { age } = props;
	const infoTextForAgeGroup = useCallback((infoText: string) => {
		if (!infoText) return null;
		const datasetSize = age && age <= 55 ? '~85K' : '185K';
		const datasetAgeRange =  age && age <= 55 ? '39-55' : '39-71';
		infoText = infoText.replace('{dataset-size}', datasetSize)
		infoText = infoText.replace('{dataset-age-range}', datasetAgeRange)
		return infoText;
	}, [age]);
	const cad10yInfoText = infoTextForAgeGroup(cadReportStrings.CAD_10_YEAR_RISK_INFO_TEXT)

	const highCac = !!(props.cac && (props.cac > 100));
	const highCacScoreMsg = `${props.cac > 300 ? 'Very high' : 'High'} coronary artery calcium score`;
	const bothRisksHigh = !!(highCac && (props.risk10yrs >= 7.5));
	let risk10yrs = '';
	if (props.risk10yrs) {
		risk10yrs = props.risk10yrs > 30 ? "> 30%" : `${props.risk10yrs.toFixed(1)}%`;
	}
	return (
		<Box
			color={COLORS.REPORT_TEXT_GRAY}
			borderRadius={40}
			borderWidth={5}
			borderColor={"gray.100"}
			mr={props.printing ? "80px" : 0}
			my={props.isMobile ? "10px" : 0}
		>
			<Box mx={props.isMobile || props.printing ? "10px" : "30px"} mt={"12px"} mb={props.isMobile ? "10px" : "20px"}>
				<Text fontWeight={"bold"} color={COLORS.REPORT_TEXT} mt={props.isMobile ? 0 : "10px"} textAlign={"center"}>
					OVERALL RISK
				</Text>
				<Flex mt={props.isMobile ? "10px" : "20px"} fontSize={props.isMobile ? 12 : 16}>
					<Box w={"120px"} ml={props.isMobile ? 0 : "10px"} key={uuid4()} >
						<Text minH={"30px"}>{' '}</Text>
						<Text textAlign={"left"} mr={props.isMobile ? "10px" : "30px"}>{props.isMobile ? "LEVEL" : "RISK LEVEL:"}</Text>
						<Text textAlign={"left"} mr={props.isMobile ? "10px" : "30px"}>RISK:</Text>
					</Box>
					<PopoverInfo
						trigger={<Box w={props.isMobile ? "90px" : "110px"} mr={props.isMobile ? "10px" : "40px"} key={uuid4()}>
							<Text minH={"25px"} color={COLORS.REPORT_TEXT} textAlign={"center"}>10 YEAR RISK</Text>
							<Divider w={props.isMobile ? "90px" : "110px"} mb={"5px"} borderColor={COLORS.REPORT_TEXT}/>
							<Text color={props.riskColor} fontWeight={"bold"} textAlign={"center"}>{props.riskLevel.toUpperCase()}</Text>
							<Text fontWeight={"bold"} color={props.riskColor} textAlign={"center"}>
								{risk10yrs}
							</Text>
						</Box>}
						header={cadReportStrings.CAD_10_YEAR_RISK_HEADER}
						text= {cad10yInfoText}
					/>
					<PopoverInfo
						trigger={<Box w={props.isMobile ? "100px" : "135px"} mr={"30px"}>
							<Text minH={"25px"} color={COLORS.REPORT_TEXT} textAlign={"center"}>LIFETIME RISK</Text>
							<Divider w={props.isMobile ? "100px" : "135px"} mb={"5px"} borderColor={COLORS.REPORT_TEXT}/>
							{props.age && props.age > 65 && <Box>
								<Text mx={"5px"} color={COLORS.REPORT_TEXT_GRAY} fontSize={12}
											textAlign={"center"}>{"available only for individuals of age 20-60"}</Text>
							</Box>}
							{props.age && props.age <= 65 && <Box>
								<Text color={props.lifetimeRiskColor} fontWeight={"bold"} textAlign={"center"}>
									{props.lifetimeRiskLevel.toUpperCase()}
								</Text>
								<Flex align={"end"}>
									<Text fontSize={10} color={COLORS.REPORT_TEXT_GRAY} mb={"3px"} mr={"5px"}>{'by age 75:'}</Text>
									<Text fontWeight={"bold"} color={props.lifetimeRiskColor} textAlign={"center"}>
										{props.lifetimeRisk ? `${props.lifetimeRisk.toFixed(1)}%` : ''}
									</Text>
									<Spacer/>
								</Flex>
								<Flex align={"end"}>
									<Text fontSize={10} color={COLORS.REPORT_TEXT_GRAY} mb={"3px"} mr={"5px"}>{'by age 95:'}</Text>
									<Text fontWeight={"bold"} color={props.lifetimeRiskColor} textAlign={"center"}>
										{props.lifetimeRisk95 ? `${props.lifetimeRisk95.toFixed(1)}%` : ''}
									</Text>
									<Spacer/>
								</Flex>
							</Box>}
						</Box>}
						header={cadReportStrings.LIFETIME_RISK_HEADER}
						text={cadReportStrings.LIFETIME_RISK_INFO_TEXT}
						footer={null}
					/>
				</Flex>
				{highCac &&
					<Alert status="warning" mt="10px" borderRadius="10px" maxW={"445px"} py={"10px"} variant='subtle' color={COLORS.RED_STATUS}>
						<AlertIcon/>
						<Box>
							<Flex align={"center"}>
								<AlertTitle fontSize={14}>{highCacScoreMsg}</AlertTitle>
								<Text fontSize={12} mt={"1px"}>{`(${props.cac} AU)`}</Text>
							</Flex>
							{bothRisksHigh && <Text fontSize={14} mt={"5px"}>Having both a 10 year risk > 7.5% and a high coronary artery calcium score indicate a <b>very high risk</b></Text>}
						</Box>
					</Alert>}
			</Box>
		</Box>)
}