// @flow
import React from 'react';
import obesityReportCommon from "./obesity-report-common";
import RiskChartsSectionOrMobile from "../common/reports/RiskChartsSectionOrMobile";
import {Box} from "@chakra-ui/react";

type Props = {
  percentile: number,
  quintile: number,
  deciles: Array<number>,
  oddsRatios: Array<number>,
  printing: boolean
};

export default function ObesityChartsSection(props: Props) {
  return (
      <Box
            sx={{
              '@media print': {
                pageBreakBefore: 'always;'
              }
            }}
            mt={props.printing ? "50px": 0}
      >
        <RiskChartsSectionOrMobile
          percentile={props.percentile}
          quintile={props.quintile}
          deciles={props.deciles}
          oddsRatios={props.oddsRatios}
          minY={0}
          maxY={40}
          yTickInterval={5}
          shortTrait="Obesity"
          longTrait="Obesity"
          absRiskTrendInfoText={obesityReportCommon.OBESITY_RISK_IN_POPULATION}
          riskVsAvgInfoText={obesityReportCommon.OBESITY_RISK_VS_AVERAGE}
          printing={props.printing}
        />
      </Box>
  );
}
