// @flow
import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import COLORS from '../common/colors';
import IconTextAndLink from '../common/IconTextAndLink';
import dietImage from '../images/diet.png';
import runningImage from '../images/running.png';
import alcoholImage from "../images/alcohol.png";

type Props = {
  isMobile: boolean
};

export default function ObesityRecommendationSection(props: Props) {
  return (
    <Box
      sx={{
        '@media print': {
          pageBreakBefore: 'always;'
        }
      }}
    >
      <Text mt="40px" color={COLORS.REPORT_TEXT} fontWeight="semibold">
        To reduce risk for developing obesity you can refer to the
        following:
      </Text>
      <Link
        color="teal.500"
        href="https://www.hopkinsmedicine.org/health/conditions-and-diseases/obesity/preventing-obesity"
        isExternal
        mb="20px"
      >
        https://www.hopkinsmedicine.org/health/conditions-and-diseases/obesity/preventing-obesity
      </Link>
      <IconTextAndLink
        imageSrc={dietImage}
        text="Diet"
        linkText="Preserve a healthy diet"
        link="https://www.verywellhealth.com/obesity-prevention-4014175"
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={runningImage}
        text="Exercise"
        linkText="Recommendations for physical activity"
        link="https://nutritionsource.hsph.harvard.edu/physical-activity-research/#:~:text=That's%20the%20equivalent%20of%20about,weight%20off%2C%20some%20require%20less."
        isMobile={props.isMobile}
      />
      <IconTextAndLink
        imageSrc={alcoholImage}
        text="Limited alcohol"
        linkText="Limit alcohol consumption"
        link="https://www.betterhealth.vic.gov.au/health/healthyliving/Alcohol-and-weight-gain"
        isMobile={props.isMobile}
      />
    </Box>
  );
}

ObesityRecommendationSection.propTypes = {
  isMobile: PropTypes.bool
};

ObesityRecommendationSection.defaultProps = {
  isMobile: false
};
