// @flow

const ObesityReportStrings = {
  WHAT_IS_OBESITY_TEXT1:
    'Obesity is a common condition in the United States that are defined as the increase in size ' +
    'and amount of fat cells in the body. Obesity is caused by many factors including behaviors like eating patterns, ' +
    'lack of sleep or physical activity, and some medicines, as well as genetics and family history. ' +
    'Obesity is a chronic health condition that raises the risk for heart disease — ' +
    'the leading cause of death in the United States, and is linked to many other health problems, ' +
    'including type 2 diabetes and cancer',
  WHAT_IS_OBESITY_TEXT2:
    'Data collected from 23 states in 2023 shows that more than one in three adults (35%) has obesity. ' +
    'Nearly 1 in 5 children and teens ages 2 to 19 years have obesity. Overweight and obesity can lead to ' +
    'serious health issues for people of all ages. ',
  WHAT_IS_OBESITY_REF1:
    'Emmerich SD, Fryar CD, Stierman B, Ogden CL. Obesity and severe obesity prevalence in adults: United States, August 2021–August 2023. NCHS Data Brief, no 508. Hyattsville, MD: National Center for Health Statistics. 2024. DOI: https://dx.doi.org/10.15620/cdc/159281',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 1,561,958 SNPs variants that are known to be associated ' +
    'with obesity and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall genetic risk ' +
    'for having obesity',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 individuals to ' +
    'calculate your relative risk of developing obesity ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Loic Yengo, Julia Sidorenko, Kathryn E Kemper, Zhili Zheng, Andrew R Wood, Michael N Weedon, Timothy M Frayling, Joel Hirschhorn, Jian Yang, Peter M Visscher, the GIANT Consortium, Meta-analysis of genome-wide association studies for height and body mass index in ∼700000 individuals of European ancestry, Human Molecular Genetics, Volume 27, Issue 20, 15 October 2018, Pages 3641–3649, https://doi.org/10.1093/hmg/ddy271',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO OBESITY.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_OBESITY_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 1.5M variants known to be associated with obesity. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  OBESITY_RISK_IN_POPULATION:
    'The graph shows the risk for having obesity vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having obesity was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  OBESITY_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having obesity vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default ObesityReportStrings;
