// @flow

const OsteoporosisReportStrings = {
  WHAT_IS_OSTEOPOROSIS_TEXT1:
    'Osteoporosis causes bones to become weak and brittle, so brittle that a fall or even mild stresses ' +
    'such as bending over or coughing can cause a fracture. Osteoporosis-related fractures most commonly ' +
    'occur in the hip, wrist or spine.' +
    'Bone is living tissue that is constantly being broken down and replaced. ' +
    'Osteoporosis occurs when the creation of new bone doesn\'t keep up with the loss of old bone.',
  WHAT_IS_OSTEOPOROSIS_TEXT2:
    'According to the National Osteoporosis Foundation, approximately 10 million Americans have osteoporosis ' +
    'and another 44 million have low bone density, placing them at increased risk.',
  WHAT_IS_OSTEOPOROSIS_REF1:
    'Salari, N., Ghasemi, H., Mohammadi, L. et al. The global prevalence of osteoporosis in the world: a comprehensive systematic review and meta-analysis. J Orthop Surg Res 16, 609 (2021). https://doi.org/10.1186/s13018-021-02772-0',
  HOW_DID_WE_CALC_TEXT1:
    'OpenDNA calculates your score based on analysis of your ' +
    'genetic variants. Genetic variation is the difference in DNA ' +
    'sequences between individuals within a population. These are the ' +
    'differences in DNA that make every individual different. ' +
    'OpenDNA bases the calculation on a list of 6,675,369 SNPs variants that are known to be associated ' +
    'with osteoporosis and calculates a Polygenic Risk Score (PRS) ' +
    'based on the contribution of each genetic variant to the overall genetic risk ' +
    'for having osteoporosis.',
  HOW_DID_WE_CALC_TEXT2:
    'Once your PRS is calculated it is compared ' +
    'to a group of about 339,000 individuals to ' +
    'calculate your relative risk of developing osteoporosis ' +
    'compared to that population.',
  HOW_DID_WE_CALC_REF1:
    'Morris, J.A., Kemp, J.P., Youlten, S.E. et al. An atlas of genetic influences on osteoporosis in humans and mice. Nat Genet 51, 258–266 (2019). https://doi.org/10.1038/s41588-018-0302-x',
  DISCLAIMER1:
    'THIS REPORT MAY NOT INCLUDE EVERY GENETIC MARKER RELATED TO OSTEOPOROSIS.',
  DISCLAIMER2:
    'THIS TEST IS NOT DIAGNOSTIC, IT IS A PREDICTION BASED ON PREDISPOSITION. ' +
    'AN INCREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY DEVELOP A DISEASE ' +
    'AND A DECREASED RISK SCORE DOES NOT MEAN THAT A PERSON WILL DEFINITELY NOT DEVELOP A DISEASE.',
  DISCLAIMER3:
    'THE TEST CANNOT REPLACE MEDICAL TESTING OR MEDICAL MANAGEMENT THROUGH A HEALTHCARE PROVIDER.',
  DISCLAIMER4:
    'THE RISK MAY ALSO BE INFLUENCED BY OTHER LIFESTYLE FACTORS, OTHER CLINICAL FACTORS AND ' +
    'GENETIC VARIANTS THAT ARE NOT INCLUDED IN THE ANALYSIS OR WERE NOT REPORTED PROPERLY.',
  DISCLAIMER5:
    'YOUR RISK IS COMPARED TO A POPULATION WHERE THE MAJORITY IS OF EUROPEAN DESCENT. ' +
    'THE ACCURACY OF THE RESULTS MAY VARY IF YOU HAVE A DIFFERENT BACKGROUND.',
  DISCLAIMER6:
    'TEST RESULTS SHOULD BE INTERPRETED BY A HEALTHCARE PROVIDER. MEDICAL MANAGEMENT AND ' +
    'DECISION-MAKING FOR PREVENTION PRACTICES SHOULD BE DONE IN THE CONTEXT OF THE PATIENT’S ' +
    'FULL CLINICAL HISTORY AND NOT BASED SOLELY ON THE TEST RESULTS.',
  GENETIC_RISK_OSTEOPOROSIS_INFO_TEXT:
    'The polygenic risk score (PRS) of the patient is calculated by analyzing ' +
    'more than 1.4M variants known to be associated with osteoporosis. ' +
    'The PRS is then compared to a reference scale, built from scores of ~339K individuals. ' +
    'The scale is divided into percentiles that reflect the genetic risk level. ' +
    "The patient risk percentile is determined by comparing the individual's PRS value against the threshold risk values of each percentile.",
  OSTEOPOROSIS_RISK_IN_POPULATION:
    'The graph shows the risk for having osteoporosis vs. the genetic risk level. Genetic risk (PRS) was calculated for ' +
    'more than 339K individuals. The scores were divided into deciles and the risk of having osteoporosis was calculated for each decile. ' +
    "Gray bars indicate the risk level per decile. The highlighted bar represents patient risk level based on the individual's genetic risk.",
  OSTEOPOROSIS_RISK_VS_AVERAGE:
    'The graph shows patient risk level for having osteoporosis vs. the average risk in the population. ' +
    "The risk of the patient is determined based on the individual's genetic risk level. "
};

export default OsteoporosisReportStrings;
