// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getPatientId } from './capilots-utils';
import ObesityRecommendationSection from '../obesity/ObesityRecommendationSection';
import ObesityExplanatorySection from '../obesity/ObesityExplanatorySection';
import obesityImage from '../images/obesity.png';
import withNavigation from '../main/WithRoutes';
import ObesityChartsSection from '../obesity/ObesityChartsSection';
import obesityReportCommon from "../obesity/obesity-report-common";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import PatientAttributes from "../common/reports/PatientAttributes";
import {defaultObesityCalculatorUserAttribute} from "../common/reports/report-common";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";
import BmiCalculator from "../obesity/BmiCalculator";

type Props = {
  location: any,
  params: any,
  visit: Object,
  printing: boolean,
  patientId: ?string,
  obesity: Object,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean,
  topMiddlePane: any
};

export class PilotObesityRiskReportImpl extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    const printingPatientAttr = this.props.printing && this.props.obesity &&
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            riskTrait="Obesity"
            riskLevel={getRiskLevelByPercentile(this.props.obesity.obesity.percentile + 1)}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={defaultObesityCalculatorUserAttribute}
            spacing={"7px"}
          >
          </PatientAttributes>
        </Box>;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="OBESITY RISK REPORT"
          configTrait="obesity"
          loading={this.props.loading}
          titleImage={obesityImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          geneticRiskInfoText={[
            obesityReportCommon.GENETIC_RISK_OBESITY_INFO_TEXT
          ]}
          riskPercentile={
            this.props.obesity ? this.props.obesity.obesity.percentile : null
          }
          topMiddlePane={this.props.topMiddlePane}
          patientAttributesSection={printingPatientAttr}
          chartsSection={
            this.props.obesity ?
              <ObesityChartsSection
                percentile={this.props.obesity.obesity.percentile}
                quintile={Math.floor(
                  this.props.obesity.obesity.percentile / 20
                )}
                deciles={this.props.obesity.obesity.deciles}
                oddsRatios={this.props.obesity.obesity.odds_ratio_list}
                isMobile={isMobile}
                printing={this.props.printing}
              /> : null
          }
          calculatorSection={this.props.visit && this.props.visit.height && this.props.visit.weight &&
              <Box mt="20px"
                    sx={{
                      '@media print': {
                        pageBreakBefore: 'always;'
                      }
                    }}
              >
                <BmiCalculator
                  weight={this.props.visit.weight}
                  height={this.props.visit.height}
                  printing={this.props.printing}
                  showTreatmentRecommendations={!this.props.printing}
                  isMobile={isMobile}
                />
              </Box>
          }
          recommendationSection={
            !this.props.isClinicianView && <ObesityRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<ObesityExplanatorySection isMobile={isMobile} printing={this.props.printing}/>}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotObesityRiskReportImpl));
