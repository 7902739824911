// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import {getPatientId, loadClinicalData} from '../filters';
import ObesityRecommendationSection from '../../obesity/ObesityRecommendationSection';
import ObesityExplanatorySection from '../../obesity/ObesityExplanatorySection';
import obesityImage from '../../images/obesity.png';
import withNavigation from '../../main/WithRoutes';
import ObesityChartsSection from '../../obesity/ObesityChartsSection';
import obesityReportCommon from "../../obesity/obesity-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";
import BmiCalculator from "../../obesity/BmiCalculator";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoObesityRiskReportImpl extends React.Component<Props, State> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const obesityes = DemoObesityRiskReportImpl.loadDemoData();
    const key = `obesity${this.patientId}`;
    this.obesity = obesityes[key] === undefined ? obesityes.obesity1 : obesityes[key];
    this.clinical = loadClinicalData(this.patientId);
  }

  obesity: Object;
  clinical: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="OBESITY RISK REPORT"
          configTrait="obesity"
          titleImage={obesityImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          riskPercentile={this.obesity.obesity.percentile}
          geneticRiskInfoText={[
            obesityReportCommon.GENETIC_RISK_OBESITY_INFO_TEXT,
          ]}
          chartsSection={
            <ObesityChartsSection
              percentile={this.obesity.obesity.percentile}
              quintile={Math.floor(
                this.obesity.obesity.percentile / 20
              )}
              deciles={this.obesity.obesity.deciles}
              oddsRatios={this.obesity.obesity.odds_ratio_list}
              printing={this.props.printing}
            />
          }
          calculatorSection={
              <Box mt="20px"
                    sx={{
                      '@media print': {
                        pageBreakBefore: 'always;'
                      }
                    }}
              >
                <BmiCalculator
                  weight={this.clinical.weight}
                  height={this.clinical.height}
                  printing={this.props.printing}
                  showTreatmentRecommendations={!this.props.printing}
                  isMobile={isMobile}
                />
              </Box>
          }
          recommendationSection={
            <ObesityRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<ObesityExplanatorySection isMobile={isMobile} printing={this.props.printing}/>}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoObesityRiskReportImpl));
