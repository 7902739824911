// @flow
import React, {useCallback, useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import {
	getBmiRiskColorByGaugeValue,
	getBmiRiskLevelByGaugeValue
} from "../common/risk-colors";
import {getBmiOuterPanePlotBands, getBmiRiskPlotBands} from "../common/risk-gauge-utils";
import CalculatorOrMobile from "../common/calculator/CalculatorOrMobile";
import calcBmi from "../utils/bmi";
import {getHeightAttr, getWeightAttr} from "../common/calculator/calculator-attr";
import ObesityPersonalizedRecommendations from "./ObesityPersonalizedRecommendations";

type Props = {
	weight: number,
	height: number,
	printing: boolean,
	showTreatmentRecommendations: boolean,
	isMobile: boolean
};

export default function BmiCalculator(props: Props) {
	const bmi = calcBmi(props.weight, props.height / 100)
	const runLogReg = useCallback((_sex: boolean, input: Object, skipCallback: boolean, quiet: boolean) => {
		const bmi = calcBmi(input.weight, input.height / 100)
		if (!bmi) return {
			percentile: 0,
			risk: 0,
			odds: 0
		}
		const percentile = (bmi - 15) / 30;
		return {
			percentile: percentile,
			risk: bmi - 15,
			odds: bmi
		}
	}, []);

	const {height, weight, isMobile, printing} = props;
	const numericAttrs = useMemo(() => {
		return [
			getWeightAttr(weight),
			getHeightAttr(height)
		]
	}, [weight, height]);

	const infoTexts = {
		riskLevel: {
			header: "",
			text: "",
			footer: ""
		},
		model: undefined,
	};

	const getRiskLabel = useCallback((current, expected, fixDigits, highLimit) => {
			if (expected < 0) return "< 15";
			if (expected > 30) return "> 45";
			return (expected + 15).toFixed(1);
		}, []
	);

	const callbackGetRecommendations = useCallback((percentile, risk) => {
			if (printing) return null;
			return <ObesityPersonalizedRecommendations bmi={bmi ?? 0} height={height} weight={weight} isMobile={isMobile}/>
		}, [bmi, height, weight, isMobile, printing]
	)

	return (
		<Box >
			<CalculatorOrMobile
				sex={false}
				riskQuintile={0}
				riskPercentile={0.5}
				numericAttrs={numericAttrs}
				booleanAttrs={[]}
				selectionAttrs={[]}
				stdizedPrs={0}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={null}
				callbackGetRecommendations={callbackGetRecommendations}
				disease={null}
				minRisk={15}
				maxRisk={45}
				showRisk={true}
				showRelativeToPop={false}
				riskGauge={{
					hideTab: true,
					title: "BMI",
					callbackGetPlotBands: getBmiRiskPlotBands,
					callbackGetOuterPlotBands: getBmiOuterPanePlotBands,
					lines: []
				}
				}
				relativeToPopGauge={{
					title: "",
					changeLabel: ''
				}}
				callbackGetColorByRisk={getBmiRiskColorByGaugeValue}
				callbackGetRiskLevel={getBmiRiskLevelByGaugeValue}
				infoTexts={infoTexts}
				printing={props.printing}
				callbackOnSavePatientAttrs={null}
				callbackTranslateOverallRiskValue={null}
				allowPatientAttrsEditing={false}
				allowPatientAttrsFirstMissingEditing={false}
				relativeToPopTitle={""}
				callbackGetGaugeHeader={null}
				showGender={false}
				callbackGetRiskLabel={getRiskLabel}
				headerTitle={'EFFECT OF WEIGHT ON BMI LEVEL'}
				showChangeDetails={false}
			/>
		</Box>);
}
