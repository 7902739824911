// @flow
import React, {useCallback, useEffect, useState} from 'react';
import { STSClient, AssumeRoleWithWebIdentityCommand } from "@aws-sdk/client-sts";
import {
  Box, Flex, Image,
  Stack,
  Spacer,
  Text,
} from '@chakra-ui/react';

import AWS, {Credentials} from "aws-sdk";
import queryString from "query-string";
import {
  extractDepartment,
  getFederatedRoleArn,
  performTokenRequest,
  splitPatientAndPractice /*, performTokenRequest*/
} from "./athena";
import {AWS_REGIONS, ENV} from "../config/env_config";
import dnaSampleImage from "../images/dna_sample.png";
import {getOpendnaPatientFromAthenaPatient} from "../api/capilots";
import InProgressImpl from "../common/InProgress";
import withNavigation from "../main/WithRoutes";
import COLORS from "../common/colors";
import IconAttribute from "../common/IconAttributes";
import {athenaCollectPatientInfo} from "../api/athenaapi";
import AccPanel from "../cad/AccPanel";
import {SingleSignOn} from "./SingleSignOn";
import PilotPatientRisks from "../capilots/PilotPatientRisks";

type Props = {
  location: any,
  debugMode: ?boolean,
  debugPatientId: ?string,
  debugPatientResultsReady: ?boolean,
  debugLoggedIn: ?boolean
};

export function AthenaAuthImpl(props: Props) {
  const { location } = props;
  console.log("athenahealth auth");
  console.log(location.pathname);

  const [token, setToken] = useState(undefined);
  const [loggedIn, setLoggedIn] = useState(false);
  const [patientId, setPatientId] = useState(undefined);
  const [patientResultsReady, setPatientResultsReady] = useState(false);
  const [patientInfo, setPatientInfo] = useState(undefined);

  const retrieveAccInfo = useCallback(async (tokenRes: Object) => {
      const patientAndPractice = splitPatientAndPractice(tokenRes.patient);
      const department = extractDepartment(tokenRes.ah_department);
      const info = await athenaCollectPatientInfo(patientAndPractice.patient, patientAndPractice.practice, department);
      setPatientInfo(info.data);
    }, []);

  const onLogin = useCallback(async (tokenRes: Object) => {
    const idToken = tokenRes.id_token;
    const input = {
      "DurationSeconds": 3600,
      "RoleArn": getFederatedRoleArn(),
      "RoleSessionName": "athena-health-session",
      "WebIdentityToken": idToken
      // "Policy": "{\"Version\":\"2012-10-17\",\"Statement\":[{\"Sid\":\"Stmt1\",\"Effect\":\"Allow\",\"Action\":\"s3:ListAllMyBuckets\",\"Resource\":\"*\"}]}",
    };
    const client = new STSClient({ region: AWS_REGIONS[ENV] });
    const command = new AssumeRoleWithWebIdentityCommand(input);
    const response = await client.send(command);
    AWS.config.credentials = new Credentials(
      response.Credentials.AccessKeyId,
      response.Credentials.SecretAccessKey,
      response.Credentials.SessionToken
    );
    AWS.config.loginFrom = 'ATHENA';
    setLoggedIn(true);
    const patient = await getOpendnaPatientFromAthenaPatient(tokenRes)
    setPatientId(patient.patient_id);
    if (patient.patient_id) {
      const hasGeneticResults = patient.status === "READY"
      setPatientResultsReady(hasGeneticResults);
      // if (!hasGeneticResults) {
      //   await retrieveAccInfo(tokenRes);
      // }
    } else {
      await retrieveAccInfo(tokenRes);
    }
  }, [retrieveAccInfo]);

  const {debugMode, debugLoggedIn, debugPatientResultsReady, debugPatientId} = props;

  const setDebugState = useCallback(async () => {
    setPatientId(debugPatientId);
    setLoggedIn(debugLoggedIn);
    setPatientResultsReady(debugPatientResultsReady);
    if (!debugPatientResultsReady) {
      await retrieveAccInfo({patient: "a-19596874.E-1", ah_department: "a-19596874.Department-150"})
    }
  }, [debugLoggedIn, debugPatientResultsReady, debugPatientId, retrieveAccInfo])

  useEffect(() => {
    async function tokenize() {
      const queryParams = queryString.parse(location.search);
      console.log("AthenaAuthImpl code");
      console.log(queryParams.code);
      if (queryParams.code) {
        performTokenRequest(queryParams.code)
            .then(response => response.json())
            .then(result => {
              setToken(result);
              onLogin(result);
            });
      }
    }
    if (!debugMode) {
      if (!token) {
        tokenize();
      }
    }
  }, [token, location, onLogin, debugMode, setDebugState])

  useEffect(() => {
    if (debugMode) {
      setDebugState();
    }
  }, [debugMode, setDebugState])

  const needToShowAcc = !patientInfo && patientId === null;
  const showAcc = patientInfo && patientId === null;

  return (
      <Box>
        {!loggedIn && (<SingleSignOn/>)}
        {loggedIn && (
            <>
              {patientId && <PilotPatientRisks patientId={patientId} />}
              {showAcc && (<Box m={"50px"}>
                {patientId && !patientResultsReady && (
                  <Stack ml={"70px"} color={COLORS.REPORT_TEXT} spacing={"10px"}>
                    <Text fontSize={20} fontWeight={"bold"}>Genetic results are not yet available</Text>
                    <Text>The currently shown results present the standard 10-year cardiovascular risk which does not involve any genetic insights</Text>
                    <Text>Normally it takes a couple of weeks for the results to arrive</Text>
                  </Stack>)}
                {patientId === null && (
                  <Stack ml={"70px"} color={COLORS.REPORT_TEXT} spacing={"10px"}>
                    <Text fontSize={20} fontWeight={"bold"}>An OpenDNA genetic test has not been ordered for this patient</Text>
                    <Text>You can order an OpenDNA genetic test using the new order workflow in AthenaHealth</Text>
                    <Text ml={"10px"}>The currently shown results present the standard 10-year cardiovascular risk which does not involve any genetic insights</Text>
                  </Stack>)}
                <AccPanel patientInfo={patientInfo}/>
              </Box>)}
              {needToShowAcc && (<Box mx={"8%"} align="center">
                <Flex color={COLORS.REPORT_TEXT} my={"50px"} >
                  <Spacer/>
                  <Box>
                    <Image src={dnaSampleImage} h="256px" alt="" mt="50px" mb={"20px"} />
                    <InProgressImpl label="Retrieving data for ACC score calculation..." />
                  </Box>
                  <Spacer/>
                </Flex>
                <Box mx="5%" mt={"10px"}>
                  <Box minH={"30px"}/>
                  <IconAttribute precedingLine />
                </Box>
              </Box>)}
              {patientId === undefined && !patientInfo && <Spacer/>}
              {patientId === undefined && !patientInfo && (<Box mt={"50px"}>
                <InProgressImpl label="loading patient results..." />
              </Box>)}
            </>)
        }
      </Box>
  );
}

export default withNavigation(AthenaAuthImpl);
