// @flow
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Box, Stack, Text, Flex, Image} from '@chakra-ui/react';
import LabeledTextWithUnits from "../LabelTextAndUnits";
import COLORS from "../colors";
import type {BooleanAttr, NumericAttr, SelectionAttr} from "./calculator-attr";
import detailsImage from "../../images/details.png";

type Props = {
  sex: boolean,
  showGender?: boolean,
  title?: string,
  geneticRiskTrait?: string,
  geneticRiskLevel?: string,
  geneticRiskTrait2?: string,
  geneticRiskLevel2?: string,
	numericAttrs: Array<NumericAttr>,
	booleanAttrs: Array<BooleanAttr>,
	selectionAttrs: Array<SelectionAttr>,
  showBorder?: boolean,
  fontSize?: number,
  mbTitle?: string,
  labelPrefix?: string,
  titleFontWeight?: string,
  spacing?: string
};

export default function MobileCalculatorPatientAttributes(props: Props) {
  const labelPrefix = props.labelPrefix ? props.labelPrefix : '';

  function getBinaryYesNoText(binaryField) {
    let yesNoText = '';
    if (binaryField !== undefined) yesNoText = binaryField ? 'yes' : 'no';
    return yesNoText;
  }
  function getGenderBySex(sex: boolean) {
    return sex ? 'male' : 'female';
  }

  const getResetValue = useCallback((attr)=> {
    if (!attr.resetValue) return undefined;
    if (typeof attr.resetValue === "string") return attr.resetValue;
    return attr.resetValue.toFixed(attr.fixDigits);
  }, [])

  return (
    <Box minW={"100%"} color={COLORS.REPORT_TEXT_GRAY}>
      <Flex ml={"5px"}>
        <Image src={detailsImage} h="16px" w="16px" mr="5px" />
        <Box fontSize={13} mb={"5px"}>
          {props.title && <Text ml={"10px"} fontWeight={props.titleFontWeight}>
            {props.title}
          </Text>}
        </Box>
      </Flex>
      <Box ml={"-15px"}>
        <Box fontSize={12} minW={"100%"}>
          {props.geneticRiskTrait && <LabeledTextWithUnits
            align="left"
            labelWidth="250px"
            textWidth="100px"
            textAlign="left"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            textWeight={"semibold"}
            title={`Genetic risk for ${props.geneticRiskTrait}`}
            value={props.geneticRiskLevel}
            trendIcon={null}
            unitsWidth="0px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units={''}
            marginBottom={"0px"}
          />}
          {props.geneticRiskTrait2 && <LabeledTextWithUnits
            align="left"
            labelWidth="250px"
            textWidth="100px"
            textAlign="left"
            labelColor={COLORS.REPORT_TEXT_GRAY}
            textColor={COLORS.REPORT_TEXT_GRAY}
            textWeight={"semibold"}
            title={`Genetic risk for ${props.geneticRiskTrait2}`}
            value={props.geneticRiskLevel2}
            trendIcon={null}
            unitsWidth="0px"
            unitsColor={COLORS.REPORT_TEXT_GRAY}
            units={''}
            marginBottom={"0px"}
          />}
        </Box>
        <Flex align="top" fontSize={12} mt="5px">
          <Stack spacing={"1px"} w={"48%"} mr={"1%"}>
            {props.showGender && <LabeledTextWithUnits
              align="left"
              labelWidth="100px"
              textWidth="60px"
              textAlign="left"
              labelColor={COLORS.REPORT_TEXT_GRAY}
              textColor={COLORS.REPORT_TEXT_GRAY}
              textWeight={"semibold"}
              trendIcon={null}
              title="Gender"
              value={getGenderBySex(props.sex)}
              marginBottom={"0px"}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={''}
            />}
            {props.numericAttrs.map(attr =>
              <LabeledTextWithUnits
                align="left"
                labelWidth="100px"
                textWidth="20px"
                textAlign="left"
                title={`${labelPrefix}${attr.displayText}`}
                trendIcon={null}
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                textWeight={"semibold"}
                value={getResetValue(attr)}
                unitsWidth="20px"
                unitsColor={COLORS.REPORT_TEXT_GRAY}
                units={attr.units}
                unitsFontSize={8}
                marginBottom={"0px"}
              />)
            }
          </Stack>
          <Stack spacing={"1px"} w={"44%"}>
            {props.booleanAttrs.map(attr =>
              <LabeledTextWithUnits
                align="left"
                labelWidth="125px"
                textWidth="38px"
                textAlign="left"
                title={attr.displayText}
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                textWeight={"semibold"}
                trendIcon={null}
                value={getBinaryYesNoText(attr.resetValue ?? attr.defaultValue)}
                unitsWidth="0px"
                unitsColor={COLORS.REPORT_TEXT_GRAY}
                units={''}
                marginBottom={"0px"}
              />)
            }
            {props.selectionAttrs.map(attr =>
              <LabeledTextWithUnits
                align="left"
                labelWidth="90px"
                textWidth="80px"
                textAlign="left"
                title={attr.displayText}
                labelColor={COLORS.REPORT_TEXT_GRAY}
                textColor={COLORS.REPORT_TEXT_GRAY}
                textWeight={"semibold"}
                trendIcon={null}
                value={attr.keyNameOptions[attr.resetValue ?? attr.defaultValue]}
                unitsWidth="0px"
                unitsColor={COLORS.REPORT_TEXT_GRAY}
                units={''}
                marginBottom={"0px"}
              />)
            }
          </Stack >
        </Flex>
      </Box>
    </Box>
  );
}

MobileCalculatorPatientAttributes.propTypes = {
  geneticRiskTrait: PropTypes.string,
  geneticRiskLevel: PropTypes.string,
  geneticRiskTrait2: PropTypes.string,
  geneticRiskLevel2: PropTypes.string,
  title: PropTypes.string,
  showBorder: PropTypes.bool,
  fontSize: PropTypes.number,
  mbTitle: PropTypes.string,
  labelPrefix: PropTypes.string,
  titleFontWeight: PropTypes.string,
  spacing: PropTypes.string,
  showGender: PropTypes.bool
};

MobileCalculatorPatientAttributes.defaultProps = {
  geneticRiskTrait: undefined,
  geneticRiskLevel: undefined,
  geneticRiskTrait2: undefined,
  geneticRiskLevel2: undefined,
  title: undefined,
  showBorder: true,
  fontSize: 12,
  labelPrefix: '',
  titleFontWeight: 'bold',
  showGender: true
};
