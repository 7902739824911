// @flow
const COLORS = {
  RED_LIGHT: 'rgba(188, 15, 41, 0.5)',
  GREEN_LIGHT: 'rgba(70, 168, 83, 0.5)',
  YELLOW_LIGHT: 'rgba(255, 228, 181, 0.8)',
  RED_STATUS: 'rgba(232, 102, 105, 1)',
  RED_STATUS_SLIGHT_TRANSPARENT: 'rgba(232, 102, 105, 0.75)',
  YELLOW_STATUS: 'rgba(229, 172, 0, 1)',
  YELLOW_STATUS_SLIGHT_TRANSPARENT: 'rgba(229, 172, 0, 0.6)',
  GREEN_STATUS: 'rgba(99, 168, 85, 1)',
  GREEN_STATUS_SLIGHT_TRANSPARENT: 'rgba(99, 168, 85, 0.4)',
  ORANGE_STATUS: 'rgba(235, 95, 7, 1)',
  GRAY_64: 'rgba(64, 64, 64, 1)',
  GRAY_128: 'rgba(128, 128, 128, 1)',
  GRAY_128_TRANSPARENCY_0_2: 'rgba(128, 128, 128, 0.2)',
  GRAY_192_TRANSPARENCY_0_2: 'rgba(192, 192, 192, 0.2)',
  GRAY_192: 'rgba(192, 192, 192, 1)',
  BLACK: 'rgba(0, 0, 0, 1.0)',
  HIGHCHARTS_1ST_SERIES: 'rgba(124, 181, 236, 1.0)',
  HIGHCHARTS_2ND_SERIES: 'rgba(74, 84, 108, 1.0)',
  HIGHCHARTS_TITLE: 'rgba(113, 131, 172, 1.0)',
  LABEL_TEXT_COLOR: 'rgba(47, 85, 151, 1.0)',
  BP_TITLE_BAR_COLOR: 'rgba(113, 128, 150, 1.0)',
  CHEMO_TITLE_BAR_COLOR: 'rgba(47, 85, 151, 1.0)',
  LINK_COLOR: '#3F6699',
  REPORT_TEXT: 'rgba(47, 84, 150, 1)',
  REPORT_TEXT_GRAY: 'rgba(144, 144, 144, 1)',
  REPORT_SELECTED_ITEM: 'rgba(190, 227, 248, 1)',
  MOBILE_BACKGROUND: 'gray.100',
  TAB_BACKGROUND: 'rgba(190, 227, 248, 1)',
  DARK_BLUE: 'rgba(6, 21, 81, 1)',
  HOME_GREEN: 'rgba(66, 184, 134, 1)',
  WARNING_ALERT_BG: 'rgba(254, 235, 200, 1)',
};

export function rgbaToHex(srgba: string) {
  const rgba = srgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return rgba && rgba.length === 4
    ? `#${`0${parseInt(rgba[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
        rgba[2],
        10
      ).toString(16)}`.slice(-2)}${`0${parseInt(rgba[3], 10).toString(
        16
      )}`.slice(-2)}`
    : '';
}

export function getHoverBgColor(color: string) {
  const rgba: any = color.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  let r = parseInt(rgba[1], 10);
  let g = parseInt(rgba[2], 10);
  let b = parseInt(rgba[3], 10);
  r = r <= 235 ? r + 20 : 255;
  g = g <= 235 ? g + 20 : 255;
  b = b <= 235 ? b + 20 : 255;
  if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) return color;
  return `rgba(${r}, ${g}, ${b}, 1)`;
}

export function getHoverBgColor2(color: string) {
  const rgba: any = color.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  let r = parseInt(rgba[1], 10);
  let g = parseInt(rgba[2], 10);
  let b = parseInt(rgba[3], 10);
  r = r <= 235 ? r + 10 : 255;
  g = g <= 235 ? g + 10 : 255;
  b = b <= 235 ? b + 10 : 255;
  if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) return color;
  return `rgba(${r}, ${g}, ${b}, 1)`;
}

export function changeTransparency(rgbColor: string, newTransparency: number) {
  return `${rgbColor.slice(
    0,
    rgbColor.lastIndexOf(',') + 1
  )}${newTransparency})`;
}

export default COLORS;


export const CARDIO_COLORS = {
  CAD: 'rgb(15, 41, 86, 1)',
  T2D: 'rgb(47, 84, 150, 1)',
  HTN: 'rgb(124, 181, 236, 1)',
  CHOL: 'rgb(125, 155, 175, 1)'
};
