// @flow
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {Box, Flex, Stack, Text} from '@chakra-ui/react';
import LabeledText from '../LabeledText';
import { userAttributesFlags } from './report-common';
import LabeledTextWithUnits from "../LabelTextAndUnits";
import COLORS from "../colors";
import CalculatorPatientAttributes from "../calculator/CalculatorPatientAttributes";

type Props = {
  patientId?: string,
  gender?: ?string,
  age?: ?number,
  cac?: ?number,
  sbp?: ?number,
  dbp?: ?number,
  hdl?: ?number,
  ldl?: ?number,
  tc?: ?number,
  smoker?: ?boolean,
  riskTrait?: string,
  riskLevel?: string,
  riskTrait2?: string,
  riskLevel2?: string,
  bmi?: ?number,
  a1c?: ?number,
  bpTreated?: boolean,
  diabetes?: boolean,
  fhDiabetes?: boolean,
  title?: string,
  showBorder?: boolean,
  fontSize?: number,
  mbTitle?: string,
  labelPrefix?: string,
  showAttributeFlags?: number,
  titleFontWeight?: string,
  spacing?: string,
  additionalNumericAttrs?: ?Array<Object>,
  additionalSelectionAttrs?: ?Array<Object>,
  additionalBoolAttrs?: ?Array<Object>
};

export default function PatientAttributesImpl(props: Props) {
  const {showAttributeFlags} = props;
  const showAttribute = useCallback((val: any, flag: number) => {
    if (val) return true;
    if (!showAttributeFlags) return false;
    // eslint-disable-next-line no-bitwise
    return flag & showAttributeFlags;
  }, [showAttributeFlags]);

  let smoking = '';
  if (props.smoker !== undefined) smoking = props.smoker ? 'Yes' : 'No';
  let diabetic = '';
  if (props.diabetes !== undefined) diabetic = props.diabetes ? 'Yes' : 'No';
  let bpTreated = '';
  if (props.bpTreated !== undefined) bpTreated = props.bpTreated ? 'Yes' : 'No';
  let fhDiabetes = '';
  if (props.fhDiabetes !== undefined)
    fhDiabetes = props.fhDiabetes ? 'Yes' : 'No';
  const labelPrefix = props.labelPrefix ? props.labelPrefix : '';
  const rightSide = props.additionalNumericAttrs || props.additionalBoolAttrs || props.additionalSelectionAttrs;
  return (
    <Box
      minW="310px"
      // maxW="310px"
      borderColor="gray.100"
      borderWidth={props.showBorder ? 1 : 0}
      mx="15px"
      mb="10px"
    >
      <Flex>
        <Stack ml="20px" fontSize={props.fontSize} spacing={props.spacing}>
          <Text mt="25px" mb={props.mbTitle} fontWeight={props.titleFontWeight}>
            {props.title}
          </Text>
          {props.patientId && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="System ID"
              value={props.patientId}
            />
          )}
          {showAttribute(props.gender, userAttributesFlags.age) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="Gender"
              value={props.gender}
            />
          )}
          {showAttribute(props.age, userAttributesFlags.age) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title={`${labelPrefix}Age`}
              value={props.age}
            />
          )}
          {showAttribute(props.bmi, userAttributesFlags.bmi) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign={"left"}
              title={`${labelPrefix}BMI`}
              value={props.bmi}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'kg/m\u00B2'}
            />
          )}
          {props.riskTrait && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title={`Genetic risk for ${props.riskTrait}`}
              value={props.riskLevel}
            />
          )}
          {props.riskTrait2 && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title={`Genetic risk for ${props.riskTrait2}`}
              value={props.riskLevel2}
            />
          )}
          {showAttribute(props.cac, userAttributesFlags.cac) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}Coronary Artery Calcium`}
              value={props.cac ? props.cac : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'AU'}
            />
          )}
          {showAttribute(props.tc, userAttributesFlags.tc) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}Total Cholesterol`}
              value={props.tc ? props.tc : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mg/dL'}
            />
          )}
          {showAttribute(props.hdl, userAttributesFlags.hdl) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}HDL`}
              value={props.hdl ? props.hdl : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mg/dL'}
            />
          )}
          {showAttribute(props.ldl, userAttributesFlags.ldl) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}LDL`}
              value={props.ldl ? props.ldl : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mg/dL'}
            />
          )}
          {showAttribute(props.sbp, userAttributesFlags.sbp) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}SBP`}
              value={props.sbp}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mmHg'}
            />
          )}
          {showAttribute(props.dbp, userAttributesFlags.dbp) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}DBP`}
              value={props.dbp}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'mmHg'}
            />
          )}
          {showAttribute(props.a1c, userAttributesFlags.a1c) && (
            <LabeledTextWithUnits
              align="left"
              labelWidth="220px"
              textWidth="35px"
              textAlign="left"
              title={`${labelPrefix}A1C`}
              value={props.a1c ? props.a1c.toFixed(1) : undefined}
              unitsWidth="45px"
              unitsColor={COLORS.REPORT_TEXT_GRAY}
              units={'%'}
            />
          )}
          {showAttribute(props.bpTreated, userAttributesFlags.bpTreated) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="Treated for high blood pressure"
              value={bpTreated}
            />
          )}
          {showAttribute(props.diabetes, userAttributesFlags.diabetes) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="Diabetes"
              value={diabetic}
            />
          )}
          {showAttribute(props.fhDiabetes, userAttributesFlags.fhDiabetes) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="Diabetes family history"
              value={fhDiabetes}
            />
          )}
          {showAttribute(props.smoker, userAttributesFlags.smoker) && (
            <LabeledText
              align="left"
              labelWidth="210px"
              textWidth="80px"
              title="Smoker"
              value={smoking}
            />
          )}
        </Stack>
        {rightSide && <Box ml={"100px"}>
          <CalculatorPatientAttributes
            title={null}
            sex={false}
            showGender={false}
            geneticRiskTrait={null}
            geneticRiskLevel={null}
            numericAttrs={props.additionalNumericAttrs ?? []}
            booleanAttrs={props.additionalBoolAttrs ?? []}
            selectionAttrs={props.additionalSelectionAttrs ?? []}
            labelPrefix=""
            showBorder={true}
            spacing={"0px"}
            callbackOnSave={undefined}
            callbackOnCancelEdit={undefined}
          />
        </Box>}
      </Flex>
    </Box>
  );
}

PatientAttributesImpl.propTypes = {
  patientId: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.number,
  cac: PropTypes.number,
  sbp: PropTypes.number,
  dbp: PropTypes.number,
  hdl: PropTypes.number,
  ldl: PropTypes.number,
  tc: PropTypes.number,
  smoker: PropTypes.bool,
  a1c: PropTypes.number,
  bmi: PropTypes.number,
  bpTreated: PropTypes.bool,
  diabetes: PropTypes.bool,
  fhDiabetes: PropTypes.bool,
  title: PropTypes.string,
  riskTrait: PropTypes.string,
  riskLevel: PropTypes.string,
  riskTrait2: PropTypes.string,
  riskLevel2: PropTypes.string,
  showBorder: PropTypes.bool,
  fontSize: PropTypes.number,
  mbTitle: PropTypes.string,
  labelPrefix: PropTypes.string,
  showAttributeFlags: PropTypes.number,
  titleFontWeight: PropTypes.string,
  spacing: PropTypes.string,
  additionalNumericAttrs: PropTypes.object,
  additionalSelectionAttrs: PropTypes.object,
  additionalBoolAttrs: PropTypes.object
};

PatientAttributesImpl.defaultProps = {
  patientId: undefined,
  gender: undefined,
  age: undefined,
  cac: undefined,
  sbp: undefined,
  dbp: undefined,
  hdl: undefined,
  ldl: undefined,
  tc: undefined,
  smoker: undefined,
  a1c: undefined,
  bmi: undefined,
  bpTreated: undefined,
  diabetes: undefined,
  fhDiabetes: undefined,
  title: 'Patient attributes',
  riskTrait: undefined,
  riskLevel: undefined,
  riskTrait2: undefined,
  riskLevel2: undefined,
  showBorder: true,
  fontSize: 13,
  mbTitle: '20px',
  labelPrefix: '',
  showAttributeFlags: 0,
  titleFontWeight: 'bold',
  spacing: '10px',
  additionalNumericAttrs: undefined,
  additionalSelectionAttrs: undefined,
  additionalBoolAttrs: undefined
};
